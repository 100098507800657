html {
	font-family: 'Montserrat', sans-serif;
	box-sizing: border-box;
	-webkit-font-smoothing: auto;
	-moz-osx-font-smoothing: grayscale;
}

body {
	margin: 0;
	-webkit-font-smoothing: auto;
	-moz-osx-font-smoothing: grayscale;
}

*, *:before, *:after {
	box-sizing: inherit;
	font-family: inherit;
}

a:-webkit-any-link {
	color: inherit;
	cursor: pointer;
	text-decoration: none;
}